<script>
    import { Bar } from 'vue-chartjs'
    export default {
        name: "BarComponent",
        extends: Bar,
        type: 'Bar',
        
        props:{
            data:{
                type: Object
            },
            options:{
                type : Object
            }
        },
        mounted () {
            this.renderChart(this.data, this.options)
        }
    }
</script>
