<template>
    <div class="col-md-12">
        <v-row>
            <v-col cols='12' md='12'>
                <v-expansion-panels>
                    <v-expansion-panel
                        v-for="(item,i) in paneles"
                        :key="i"
                        id="panel"
                    >
                        <v-expansion-panel-header color=#efefef  >
      	                    <template v-slot:default >    
                                <span>
                                    <v-icon color="blue">mdi-chart-areaspline</v-icon> {{item.nombre}}
                                </span>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <template v-slot:default border="left" v-if="paneles[i].url != ''">
                                <iframe 
                                    id="frame" 
                                    :width=frameW   
                                    :height=frameH
                                    data-isloaded="0"						            
                                    :src="item.url"
                                    :onLoad="autoResize()"
                                    frameborder="0" allowFullScreen="false"
                                    v-if="item.tipo_item_id=='PBI'"
                                ></iframe>
                                <component 
                                    :is=item.url
                                    v-if="item.tipo_item_id=='COMPONENT_VUE'"
                                ></component>
                            </template>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import BarComponent from './graphics/BarComponent'
import DoughnutComponent from './graphics/DoughnutComponent'
import QuotationsIndicatorsComponent from './QuotationsIndicatorsComponent'

export default {
    components:{
        BarComponent,
        DoughnutComponent,
        QuotationsIndicatorsComponent 
    },
    data: ()=>({
        paneles: [],
        frameW: 800,
        frameH: 600,
        dataBar: {
            labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [12, 19, 3, 5, 2, 3],
                    fill:false,
                    borderColor: ['#2196f3'],
                    borderWidth: 2
                },{
                    label: '# of Votes2',
                    data: [14, 10, 13, 15, 22, 7],
                    fill:false,
                    borderColor: ['#fb8c00'],
                    borderWidth: 2
                }
            ]
        } 
    }),
    

    methods: {

    	 ...mapMutations('master',['setUrl','setOverlay','setMenu','setTitleToolbar']),

    	 ...mapActions('master',['requestApi','alertNotification']),

    	 autoResize() {
               let newheight = 0;
               let newwidth = 0;
               newheight = document.getElementById("panel").scrollHeight
               newwidth = document.getElementById("panel").scrollWidth - 40
               this.frameW = newwidth
               //this.frameH = newheight
               //document.getElementById("frame").height = (newheight) + "px";
               //document.getElementById("frame").width  = newwidth ;
           },
           panelesUsuario() {
          	 	
        	   this.setUrl('panel')
               this.requestApi({
                   method : 'GET',
                   data :{
                       
                   }
               }).then(res=>{
                   console.log(res.data._embedded.panel[0])
                   this.paneles = res.data._embedded.panel[0]
               })

          },
          
           
    },
     
    created() {
    	 this.panelesUsuario()
    	 
    }

}
</script>

<style>


</style>