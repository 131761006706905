<template>
    <v-col cols=12>
        <v-row>
            <div class="col-xl-3 col-sm-4 mb-xl-0 mb-4">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="row shadow-div">
                            <div class="col-8 div-center">
                                <div class="numbers">
                                    <p class="text-sm mb-0 font-weight-bold"> POR FACTURAR <span>{{quotesToBeInvoiced}}</span></p>
                                </div>
                            </div>
                            <div class="col-4 text-end">
                                <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                    <v-icon style="top: 9px;" color="white">mdi-file-document</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-4 mb-xl-0 mb-4">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="row shadow-div">
                            <div class="col-8 div-center">
                                <div class="numbers">
                                    <p class="text-sm mb-0 font-weight-bold">PAGOS SIN CONFIRMAR {{paymentsToBeConfirmed}}</p>
                                    
                                </div>
                            </div>
                            <div class="col-4 text-end">
                                <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                    <v-icon style="top: 9px;" color="white">mdi-cash-multiple</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-4 mb-xl-0 mb-4">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="row shadow-div">
                            <div class="col-8 div-center">
                                <div class="numbers">
                                    <p class="text-sm mb-0 font-weight-bold">Cotizaciones sin ruta</p>
                                    <h5 class="font-weight-bolder mb-0">
                                        0
                                        <!-- <span class="text-success text-sm font-weight-bolder">+55%</span> -->
                                    </h5>
                                </div>
                            </div>
                            <div class="col-4 text-end">
                                <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                    <v-icon style="top: 9px;" color="white">mdi-truck-fast</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-4 mb-xl-0 mb-4">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="row shadow-div">
                            <div class="col-8 div-center">
                                <div class="numbers">
                                    <p class="text-sm mb-0 font-weight-bold">Cotizaciones sin receta</p>
                                    <h5 class="font-weight-bolder mb-0">
                                        0
                                        <!-- <span class="text-success text-sm font-weight-bolder">+55%</span> -->
                                    </h5>
                                </div>
                            </div>
                            <div class="col-4 text-end">
                                <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                    <v-icon style="top: 9px;" color="white">mdi-medical-bag</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-4 mb-xl-0 mb-4">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="row shadow-div">
                            <div class="col-8 div-center">
                                <div class="numbers">
                                    <p class="text-sm mb-0 font-weight-bold">Por despachar productos</p>
                                    <h5 class="font-weight-bolder mb-0">
                                        0
                                        <!-- <span class="text-success text-sm font-weight-bolder">+55%</span> -->
                                    </h5>
                                </div>
                            </div>
                            <div class="col-4 text-end">
                                <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                    <v-icon style="top: 9px;" color="white">mdi-barcode-scan</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-4 mb-xl-0 mb-4">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="row shadow-div">
                            <div class="col-8 div-center">
                                <div class="numbers">
                                    <p class="text-sm mb-0 font-weight-bold">Por entregar al cliente</p>
                                    <h5 class="font-weight-bolder mb-0">
                                        0
                                        <!-- <span class="text-success text-sm font-weight-bolder">+55%</span> -->
                                    </h5>
                                </div>
                            </div>
                            <div class="col-4 text-end">
                                <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                    <v-icon style="top: 9px;" color="white">mdi-home-map-marker</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>
    </v-col>
</template>

<script>

import {mapMutations, mapActions} from 'vuex'

export default {
    name:'QuotationsIndicatorsComponent',
    data: () => ({
        quotesToBeInvoiced:0,
        paymentsToBeConfirmed:0
    }),
    methods: {
        
        ...mapMutations('master',['setUrl','setOverlay']),

    	 ...mapActions('master',['requestApi']),

        getDataindicators(){
            this.setUrl('panel/0')
            this.requestApi({
                method : 'GET',
                data :{
                    
                }
            }).then(res=>{
                console.log(res)
                this.quotesToBeInvoiced = res.data.porFacturar.length
                this.paymentsToBeConfirmed= res.data.pagoSinConfirmar.length
            })
        }

    },
    mounted(){
        this.getDataindicators();
    }
}
</script>

<style>

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: 1rem;
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
}

.text-end {
    text-align: right!important;
}

.border-radius-md {
    border-radius: 0.5rem;
}

.icon-shape {
    width: 48px;
    height: 48px;
    background-position: 50%;
    border-radius: 0.75rem;
    margin: 0 auto;
}

.bg-gradient-primary {
    background-image: linear-gradient(310deg,#78dd88,#37b34a);
}

.shadow {
    box-shadow: 0 .25rem .375rem -.0625rem hsla(0,0%,8%,.12),0 .125rem .25rem -.0625rem hsla(0,0%,8%,.07)!important;
}

.shadow-div{
    border-radius: 50px;
    box-shadow: 1px 2px 13px 2px rgb(84 84 84 / 57%);
}

.numbers{
    margin: 2px 10px 0px 10px;
}

.div-center{
    display: flex;
    align-items: center;
}
</style>